<template>
  <v-container fluid>
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          SUBIENDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <div
        class="text-center"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-dialog
            v-model="dialogJuzgado"
            width="800"
            persistent
          >
            <v-card>
              <v-card-title class="text-h3 grey lighten-2">
                Datos del año a añadir
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="anioDelitos"
                      label="Ingrese año a añadir"
                      prepend-icon="mdi-numeric"
                      type="number"
                      :rules="[(v) => !!v || 'Campo requerido']"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="consultarProduccion()"
                >
                  AÑADIR
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="cerrarDialog()"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-form>
      </div>
      <v-col
        style="text-align: right"
        cols="12"
        md="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Actualización de reporte de delitos
            </div>
            <div class="text-subtitle-1 font-weight-light">
              {{ dataUsuario.nombre }}, actualice la información sobre los reportes de delitos de su corte
            </div>
          </template>
          <br>
          <v-row justify="center">
            <v-col
              cols="12"
              md="3"
            >
              <v-card-title>
                <v-btn
                  color="red"
                  fab
                  x-small
                  dark
                  @click="addJuzgado()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn> <span class="text-subtitle-1 font-weight-light"> Añadir datos del año</span>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="ml-auto"
                  label="Buscar..."
                  hide-details
                  single-line
                  style="max-width: 250px;"
                />
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                dense
                class="elevation-1"
                fixed-header
                :loading="carga"
                loading-text="Cargando, por favor espere"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, -1],
                  'items-per-page-text': 'Mostrando:',
                  'items-per-page-all-text': 'Todos'
                }"
                no-data-text="No hay datos registrados"
                no-results-text="No se encontraron resultados"
              >
                <template v-slot:[`item.editar`]="{ item }">
                  <v-btn
                    color="red"
                    fab
                    x-small
                    dark
                    @click="eliminarProduccion(item)"
                  >
                    <v-icon>mdi-delete-alert</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.sala`]="{ item }">
                  <span
                    v-if="parseInt(item.es_sala) === 1"
                  ><v-chip
                    class="ma-2"
                    color="green"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-scale-balance
                    </v-icon>
                  </v-chip></span>
                  <span
                    v-else
                  ><v-chip
                    class="ma-2"
                    color="blue"
                    label
                    text-color="white"
                  >
                    <v-icon>
                      mdi-gavel
                    </v-icon>
                  </v-chip></span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider />
          <v-spacer />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import XLSX from 'xlsx'
  export default {
    name: 'Upload',
    data: () => ({
      reglaUpload: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          'El archivo no debe pesar más de 5 MB',
      ],
      usuario: '',
      nombreUsuario: '',
      mesUpdate: '',
      sedeCorteData: '',
      carga: false,
      archivoCsv: [],
      archivoCsv2: [],
      archivoCsv3: [],
      anioDelitos: '',
      dataUpdate: [],
      urlDataSede: '',
      sumaI: 0,
      incorrectos: 0,
      urlLogo: '',
      urlDelitos: '',
      items: [],
      dialogJuzgado: false,
      search: '',
      idJuzgado: '',
      nombreJuzgado: '',
      anoMeta: '',
      metaJuzgado: '',
      updateMetaAnual: false,
      textoBoton: 'AÑADIR',
      juzgadosMetaless: [],
      nuevaMeta: false,
      obj: {
        ano_meta: '',
        mensual: '',
        id_juzgado: '',
        id_meta: '',
      },
      headers: [
        {
          text: 'Eliminar',
          value: 'editar',
        },
        {
          text: 'Año',
          value: 'anio',
        },
        {
          text: 'Cantidad',
          value: 'cantidad',
        },
      ],
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario || parseInt(this.dataUsuario.es_admin) !== 1) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', false)
      this.$store.commit('SET_LOGO', true)
      this.leerDelitos()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      cerrarSesion () {
        localStorage.setItem('sedeCorteData', null)
        localStorage.setItem('usuarioDataCSJLA', null)
        this.$router.push('/login')
      },
      addJuzgado () {
        this.updateMetaAnual = false
        this.nuevoJuzgado = true
        this.textoBoton = 'AÑADIR'
        this.dialogJuzgado = true
      },
      addNewMeta () {
        this.obj.ano_meta = this.anoMeta
        this.obj.anual = this.metaJuzgado
        this.obj.id_juzgado = this.idJuzgado
        this.updateMeta(this.obj, 1)
      },
      leerDelitos () {
        this.urlDelitos = `${window.__env.dataCfg.urlApiData}estadistica/delitos/`
        axios
          .get(
            this.urlDelitos,
          )
          .then((response) => {
            console.log(response)
            this.items = response.data.array
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      eliminarProduccion (item) {
        console.log(item)
        this.urlDelitos = `${window.__env.dataCfg.urlApiData}estadistica/eliminar_delitos/${item.anio}/`
        axios
          .get(
            this.urlDelitos,
          )
          .then((response) => {
            if (response.data.resp === true) {
              alert('SE HA ELIMINADO LA INFORMACIÓN DE DELITOS DEL AÑO ' + item.anio)
              this.leerDelitos()
            }
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      consultarProduccion () {
        this.carga = true
        const data = {
          anio: this.anioDelitos,
        }
        this.urlDelitos = `${window.__env.dataCfg.urlApiSIJWS}produccion/reporteDelitos/`
        axios
          .post(
            this.urlDelitos, data,
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.updateProduccion(response.data.data)
            }
            console.log(response.data.data)
          })
          .catch((e) => {
          console.log(e); // eslint-disable-line
          })
      },
      updateProduccion (data) {
        axios
          .post(`${window.__env.dataCfg.urlApiData}estadistica/actualizar_delitos/`, data)
          .then((r) => {
            if (r.data.resp === true) {
              alert('SE HA ACTUALIZADO LA INFORMACIÓN DE DELITOS DEL AÑO ' + this.anioDelitos)
              console.log(r)
              this.carga = false
              this.leerDelitos()
              this.cerrarDialog()
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
          })
      },
      cerrarDialog () {
        this.dialogJuzgado = false
        this.nuevaMeta = false
        this.updateMetaAnual = false
        this.anioDelitos = ''
      },
      updateResumen (e) {
        var reader = new FileReader()
        var arrayJuzgados = []
        var self = this
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result)
          var workbook = XLSX.read(data, { type: 'array' })
          const hoja1 = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[hoja1]
          arrayJuzgados = XLSX.utils.sheet_to_json(worksheet)
          for (var i = 0; i < arrayJuzgados.length; i++) {
            self.carga = true
            self.updateMeta(arrayJuzgados[i], arrayJuzgados.length)
          }
        }
        reader.readAsArrayBuffer(e)
      },
    },
  }
</script>
